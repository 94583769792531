import {
  AuthRoles,
  ErrorScreen,
  ErrorScreenVariants,
} from '@brandbank/portal-components';
import Stack from '@mui/material/Stack';
import { LoadingWrapper } from 'features/ui';
import useUser from 'hooks/useUser';
import { ReactNode } from 'react';

type RoleGuardProps = {
  children: ReactNode;
  roles: AuthRoles[];
};

export const RoleGuard = ({ children, roles }: RoleGuardProps): JSX.Element => {
  const { hasRole, isLoading, user, isAuthenticated } = useUser();

  const canViewRoles = roles.every((role) => hasRole(role));

  return (
    <LoadingWrapper isLoading={isLoading || (!user && isAuthenticated)}>
      {canViewRoles ? (
        children
      ) : (
        <Stack
          alignItems='center'
          spacing={2}
          sx={{ mt: 5, textAlign: 'center' }}
        >
          <ErrorScreen
            variant={ErrorScreenVariants.UNAUTHORISED}
            title='Not Authorised'
            subtitle={'You do not have permission to view this page.'}
          />
        </Stack>
      )}
    </LoadingWrapper>
  );
};

export const withRoleGuard = (node: ReactNode, roles: AuthRoles[]) => (
  <RoleGuard roles={roles}>{node}</RoleGuard>
);
