import useFavouriteFeedActions from '../../favourite-feeds/hooks/useFavouriteFeedActions';
import { pink } from '@mui/material/colors';
import { IconButton, CircularProgress, Tooltip } from '@mui/material/';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import { MouseEvent } from 'react';

interface FavouriteFeedIconButtonProps {
  purposeId: number;
  purposeName: string;
}

const FavouriteFeedIconButton = ({
  purposeId,
  purposeName,
}: FavouriteFeedIconButtonProps) => {
  const { isFavourited, isLoading, toggle } = useFavouriteFeedActions(
    purposeId,
    purposeName
  );

  const Icon = isFavourited ? FavoriteIcon : FavoriteBorderIcon;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    toggle();
  };

  return (
    <Tooltip
      arrow
      title={isFavourited ? 'Remove from favourites' : 'Add to favourites'}
    >
      {isLoading ? (
        <CircularProgress
          size={25}
          sx={{
            color: pink[300],
          }}
        />
      ) : (
        <IconButton onClick={handleClick}>
          <Icon sx={{ color: pink[300] }} />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default FavouriteFeedIconButton;
