import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';
import ConnectMaxDeleteButton from './ConnectMaxDeleteButton';
import { SxProps } from '@brandbank/portal-components';

type ContainerProps = {
  sx: SxProps;
  text: string;
  button: JSX.Element;
};

const Container = ({ sx, text, button }: ContainerProps) => (
  <Stack
    sx={{
      width: '100%',
      padding: '0.5rem 0.75rem',
      borderRadius: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '0.5rem',
      ...sx,
    }}
    direction='row'
  >
    <Typography fontWeight={500}>{text}</Typography>
    {button}
  </Stack>
);

type ConnectMaxActionsCardProps = {
  handleAdd: () => void;
  handleDelete?: () => void;
};

const ConnectMaxActionsCard = ({
  handleAdd,
  handleDelete,
}: ConnectMaxActionsCardProps) => {
  return (
    <Card sx={{ padding: '0.5rem' }}>
      <CardContent
        sx={{
          padding: '16px !important',
        }}
      >
        <Stack spacing={1} direction='row'>
          <Container
            text='Add a new asset mapping'
            sx={{
              bgcolor: (theme) => `${theme.palette.primary.main}1A`,
              color: 'primary.main',
              border: ({ palette }) => `solid 1px ${palette.primary.main}80`,
            }}
            button={
              <Button
                variant='contained'
                color='primary'
                sx={{ height: 'fit-content' }}
                endIcon={<AddCircleIcon />}
                onClick={handleAdd}
              >
                Add
              </Button>
            }
          />
          {handleDelete && (
            <Container
              text='Delete this Connect Max layout'
              sx={{
                bgcolor: ({ palette }) => `${palette.error.main}1A`,
                color: 'error.main',
                border: ({ palette }) => `solid 1px ${palette.error.main}80`,
              }}
              button={<ConnectMaxDeleteButton handleDelete={handleDelete} />}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ConnectMaxActionsCard;
