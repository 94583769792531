import environmentVariables from 'config/environmentVariables';
import { ProtectedLayout, PublicLayout } from 'features/layouts';
import AdminLayout from 'features/layouts/AdminLayout';
import CompaniesLayout from 'features/layouts/CompanyDetailsLayout';
import ExtractManagementLayout from 'features/layouts/ExtractManagementLayout';
import FeedDetailsLayout from 'features/layouts/FeedDetailsLayout';
import { LoadingWrapper, MicroFrontend } from 'features/ui';
import withSuspense from 'hocs/withSuspense';
import useUser, { REDIRECT_ON_LOGIN } from 'hooks/useUser';
import NotFoundPage from 'pages/404';
import { lazy, useEffect, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes as RouteContainer,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { withRoleGuard } from './components/RoleGuard';
import paths from './paths';

const { microFrontends } = environmentVariables;

// Lazy routes
const Login = lazy(() => import('pages/LoginPage'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const AdminAuditLogs = lazy(() => import('pages/AdminAuditLogs'));
const AdminApprovalRoutes = lazy(() => import('pages/AdminApprovalRoutes'));
const FeedList = lazy(() => import('pages/Feeds/FeedsList'));
const FeedDetailsOverview = lazy(
  () => import('pages/Feeds/FeedDetailsOverview')
);
const FeedDetailsProducts = lazy(
  () => import('pages/Feeds/FeedDetailsProducts')
);
const FeedDetailsAuditLogs = lazy(
  () => import('pages/Feeds/FeedDetailsAuditLogs')
);
const FeedDetailsActions = lazy(() => import('pages/Feeds/FeedDetailsActions'));
const ConnectTemplatesList = lazy(
  () => import('pages/Feeds/FeedDetailsConnectTemplatesList')
);
// const CompaniesList = lazy(() => import('pages/Companies/CompaniesList'));
const CompanyOverview = lazy(() => import('pages/Companies/CompanyOverview'));
const CompanyConnectMax = lazy(
  () => import('pages/Companies/CompanyConnectMax')
);
const CompanyLicenses = lazy(() => import('pages/Companies/CompanyLicenses'));
const CompanyUsers = lazy(() => import('pages/Companies/CompanyUsers'));
const CompanyIntegrations = lazy(
  () => import('pages/Companies/CompanyIntegrations')
);

// MicroFrontends
const DocumentationManagement = (
  <MicroFrontend host={microFrontends.docs} name='Docs' />
);

const PurposeManagement = (
  <MicroFrontend host={microFrontends.purposeManagement} name='Purpose' />
);

const ExtractManagement = (
  <MicroFrontend host={microFrontends.extractManagement} name='Extract' />
);

interface LocationState {
  from?: string;
}

// Component
const Routes = () => {
  const { isAuthenticated, isLoading, roleChecks, token } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const redirect = localStorage.getItem(REDIRECT_ON_LOGIN);

  const getLocationStateFrom = (state: unknown): string => {
    if (
      state &&
      typeof state === 'object' &&
      'from' in state &&
      typeof (state as LocationState).from === 'string'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return (state as LocationState).from!;
    }
    return '';
  };

  const from: string =
    getLocationStateFrom(location.state) &&
    getLocationStateFrom(location.state) !== '/'
      ? getLocationStateFrom(location.state)
      : redirect && redirect !== '/'
      ? redirect
      : '';

  localStorage.setItem(REDIRECT_ON_LOGIN, from);

  const routesStatus = useMemo(() => {
    if (!isAuthenticated) return 'PUBLIC';

    if (roleChecks && roleChecks.canOnlyViewDocumentation) {
      return 'DOCS_ONLY';
    }

    return 'PROTECTED';
  }, [isAuthenticated, roleChecks]);

  useEffect(() => {
    if (routesStatus === 'PROTECTED' && redirect && token) {
      navigate(redirect);

      localStorage.removeItem(REDIRECT_ON_LOGIN);
    }
  }, [navigate, redirect, routesStatus, token]);

  return (
    <LoadingWrapper isLoading={isLoading}>
      <RouteContainer>
        {routesStatus === 'DOCS_ONLY' && (
          <>
            <Route
              element={DocumentationManagement}
              path={paths.documentation}
            />

            <Route element={<Navigate to='/documentation' />} path='*' />
          </>
        )}

        {routesStatus === 'PUBLIC' && (
          <>
            {/* /login */}
            <Route element={<PublicLayout />} path={paths.login}>
              <Route element={withSuspense(<Login />)} index />
            </Route>

            {/* /documentation */}
            <Route
              element={DocumentationManagement}
              path={paths.documentation}
            />

            <Route
              element={
                <Navigate
                  to={paths.login}
                  state={{ from: window.location.pathname }}
                />
              }
              path='*'
            />
          </>
        )}

        {routesStatus === 'PROTECTED' && (
          <>
            {/* / */}
            <Route
              element={<Navigate replace to={paths.dashboard} />}
              path={paths.login}
            />

            <Route element={<ProtectedLayout />} path={paths.dashboard}>
              <Route element={withSuspense(<Dashboard />)} index />
              {/* /feeds */}
              <Route path={paths.feeds}>
                <Route
                  element={withRoleGuard(withSuspense(<FeedList />), [
                    'View Feeds List',
                  ])}
                  index
                />
                {/* /feeds/:id */}
                <Route element={<FeedDetailsLayout />} path={paths.feedDetails}>
                  <Route
                    element={withRoleGuard(
                      withSuspense(<FeedDetailsOverview />),
                      ['View Feed Details Overview']
                    )}
                    path='/feeds/:id/overview'
                  />

                  <Route
                    element={withSuspense(<FeedDetailsProducts />)}
                    path='/feeds/:id/products'
                  />

                  <Route
                    element={withRoleGuard(
                      withSuspense(<ConnectTemplatesList />),
                      ['Connect 1.0 (CRUD)']
                    )}
                    path='/feeds/:id/connect-templates'
                  />

                  <Route
                    element={withRoleGuard(
                      withSuspense(<FeedDetailsAuditLogs />),
                      ['Audit Log Viewer']
                    )}
                    path='/feeds/:id/audit-logs'
                  />

                  <Route
                    element={withRoleGuard(PurposeManagement, [
                      'Manage Purposes',
                    ])}
                    path='/feeds/:id/purpose-settings'
                  />

                  <Route
                    element={withRoleGuard(
                      withSuspense(<FeedDetailsActions />),
                      ['Feed Reset']
                    )}
                    path='/feeds/:id/actions'
                  />
                </Route>
              </Route>

              {/* Companies */}
              <Route path={paths.companies}>
                {/* <Route
                  element={withRoleGuard(withSuspense(<CompaniesList />), [
                    'Manage Purposes',
                  ])}
                  index
                /> */}

                <Route element={<NotFoundPage />} index />

                <Route
                  element={<CompaniesLayout />}
                  path={paths.companyDetails}
                >
                  <Route
                    element={withRoleGuard(withSuspense(<CompanyOverview />), [
                      'View Companies List',
                    ])}
                    path='/companies/:id/details'
                  />

                  <Route
                    element={withRoleGuard(
                      withSuspense(<CompanyIntegrations />),
                      ['Manage Company CI Config']
                    )}
                    path='/companies/:id/integrations'
                  />

                  <Route
                    element={withRoleGuard(
                      withSuspense(<CompanyConnectMax />),
                      ['Manage Company CI Config']
                    )}
                    path='/companies/:id/connect-max'
                  />

                  <Route
                    element={withRoleGuard(withSuspense(<CompanyUsers />), [
                      'Manage Company CI Config',
                    ])}
                    path='/companies/:id/users'
                  />

                  <Route
                    element={withRoleGuard(withSuspense(<CompanyLicenses />), [
                      'Manage Company CI Config',
                    ])}
                    path='/companies/:id/licenses'
                  />
                </Route>
              </Route>

              {/* /admin */}
              <Route
                element={withRoleGuard(<AdminLayout />, [
                  'Administration Viewer',
                ])}
                path={paths.admin}
              >
                <Route
                  element={<Navigate replace to={paths.adminAuditLogs} />}
                  index
                />

                <Route
                  element={withSuspense(<AdminAuditLogs />)}
                  path={paths.adminAuditLogs}
                />
                <Route
                  element={withRoleGuard(
                    withSuspense(<AdminApprovalRoutes />),
                    ['Feed Reset Approval Route Create and Update']
                  )}
                  path={paths.adminApprovalRoutes}
                />
              </Route>

              {/* /create-purpose */}
              <Route
                element={withRoleGuard(PurposeManagement, ['Manage Purposes'])}
                path={paths.createPurpose}
              />
            </Route>

            {/* /documentation */}
            <Route
              element={DocumentationManagement}
              path={paths.documentation}
            />

            {/* /extracts */}
            <Route
              element={<ExtractManagementLayout element={ExtractManagement} />}
              path={paths.extracts}
            />

            {/* 404 */}
            <Route element={<NotFoundPage />} path='*' />
          </>
        )}
      </RouteContainer>
    </LoadingWrapper>
  );
};

export default Routes;
