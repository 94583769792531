import {
  booleanToString,
  DataTable,
  DataTableColumn,
  DataTableNoItemsOptions,
  DataTableProps,
  formatDate,
  Icon,
} from '@brandbank/portal-components';
import { show } from '@ebay/nice-modal-react';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { FavouriteFeedIconButton } from 'features/favourite-feeds';
import { NoItems } from 'features/ui';
import useUser from 'hooks/useUser';
import { MouseEvent, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FeedSummary } from '../feeds.types';
import FullFeedCredentialModal from './FullFeedCredentialModal';

type FeedsTableProps = {
  isLoading?: boolean;
  items: FeedSummary[];
  onRefresh: () => void;
} & Pick<DataTableProps<FeedSummary>, 'state' | 'stateHandlers' | 'filterBy'>;

const FeedsTable = ({
  isLoading,
  items,
  onRefresh,
  state,
  stateHandlers,
  filterBy,
}: FeedsTableProps): JSX.Element => {
  const { hasRole } = useUser();

  const renderSubtypeCell = (item: FeedSummary): JSX.Element => {
    const subtype = item.purposeSubTypeDescription;

    const colorMap = {
      DEV: 'info',
      Live: 'success',
      UAT: 'warning',
    };

    return (
      <Box
        sx={{
          bgcolor: `${colorMap[subtype]}.light`,
          color: 'common.white',
          borderRadius: 1,
          m: 'auto',
          p: 0.5,
          width: 50,
        }}
      >
        <Typography fontWeight={600}>{subtype.toUpperCase()}</Typography>
      </Box>
    );
  };

  const renderCredentialCell = useCallback(
    (item: FeedSummary): JSX.Element => {
      const showModal = (item: FeedSummary) => {
        if (item.id !== null) {
          show(FullFeedCredentialModal, { purposeId: item.id });
        }
      };

      const handleClickToCopy = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Blocks react router navigation
        showModal(item);
      };

      const canViewCredentialButton =
        hasRole('View Full Feed Credential') && !!item.credentials;

      if (canViewCredentialButton) {
        return (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ fontSize: '0.875rem', margin: 'auto 0' }}>
              {item.credentials}
            </Typography>
            <Tooltip arrow title={'View full credential'}>
              <IconButton
                sx={{ width: 'fit-content' }}
                onClick={handleClickToCopy}
              >
                <Icon iconName='viewShow' />
              </IconButton>
            </Tooltip>
          </Box>
        );
      }

      return <>{item.credentials}</>;
    },
    [hasRole]
  );

  const renderPurposeDescriptionCell = (item: FeedSummary): JSX.Element => {
    return (
      <>
        {item.purposeDescription}
        {item.isDeleted ? (
          <sup>
            <Typography
              fontWeight={600}
              color={(theme) => theme.palette.error.main}
              display='inline'
              fontSize='11px'
              paddingLeft='4px'
            >
              Deleted
            </Typography>
          </sup>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderToolbarActions = (): JSX.Element => {
    return (
      <>
        {isLoading ? (
          <Skeleton height={30} sx={{ ml: 1 }} variant='circular' width={30} />
        ) : (
          <IconButton onClick={onRefresh} sx={{ ml: 1 }}>
            <Icon iconName='refresh' />
          </IconButton>
        )}
      </>
    );
  };

  const renderNoItems = (options: DataTableNoItemsOptions): JSX.Element => {
    const { hasAppliedFilters, hasSearchValue } = options;

    return (
      <>
        {hasAppliedFilters || hasSearchValue ? (
          <Typography textAlign='center'>
            No feeds found matching given filters
          </Typography>
        ) : (
          <NoItems
            actions={
              <Button color='warning' onClick={onRefresh} variant='contained'>
                Refresh
              </Button>
            }
            sx={{ minHeight: '50vh' }}
            title='No feeds found'
            message={`We couldn't find any feeds for your account.`}
          />
        )}
      </>
    );
  };

  const columns: DataTableColumn<FeedSummary>[] = useMemo(
    () => [
      {
        id: 'purposeDescription',
        label: 'Feed Purpose',
        render: renderPurposeDescriptionCell,
      },
      {
        align: 'center',
        id: 'purposeSubTypeDescription',
        label: 'Subtype',
        render: renderSubtypeCell,
      },
      {
        align: 'center',
        id: 'credentials',
        label: 'Credentials',
        render: renderCredentialCell,
      },
      {
        align: 'center',
        id: 'lastReceivedCoverage',
        label: 'Last Received Coverage',
        render: (item) => formatDate(item.lastReceivedCoverage),
        sortable: 'date',
      },
      {
        align: 'center',
        id: 'importCoverage',
        label: 'Import Coverage?',
        render: (item) => booleanToString(item.importCoverage),
      },
      {
        align: 'center',
        id: 'importFeedback',
        label: 'Import Feedback?',
        render: (item) => booleanToString(item.importFeedback),
      },
      {
        align: 'center',
        id: 'id',
        label: 'Actions',
        render: (item) => (
          <FavouriteFeedIconButton
            purposeId={item.id}
            purposeName={item.purposeDescription}
          />
        ),
      },
    ],
    [renderCredentialCell]
  );

  return (
    <DataTable
      columns={columns}
      filterBy={filterBy}
      isLoading={isLoading}
      items={items}
      renderNoItems={renderNoItems}
      renderToolbarActions={renderToolbarActions}
      searchBy={['purposeDescription', 'credentials', 'id']}
      state={state}
      stateHandlers={stateHandlers}
      rowLinkProps={{
        LinkComponent: Link,
        getLinkProps: (row) => ({
          to: `/feeds/${row.id}/overview`,
        }),
      }}
      sx={{
        '.MuiTableBody-root': {
          '.MuiTableRow-root:not(.table-row__has-no-items)': {
            textDecoration: 'none',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: 'grey.100',
            },
          },
        },
      }}
    />
  );
};

export default FeedsTable;
