import axios from 'config/axios';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';

type Response = { credential: string };

async function getFullCredential(purposeId: number) {
  const response = await axios.get<Response>('GetFullCredential', {
    params: { purposeId },
  });

  return response.data.credential;
}


const useFullFeedCredential = (purposeId: number) =>
  useQuery<string, AxiosError>(['fullCredential', purposeId], () =>
    getFullCredential(purposeId)
  );

export default useFullFeedCredential;
