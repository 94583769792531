import { useReducer } from 'react';
import {
  ProductSearchReducerAction,
  ProductSearchReducerState,
} from './productSearch.types';

const initialState: ProductSearchReducerState = {
  columnFilters: [{ id: 'productVersionStatusDescription', value: 'Current' }],
  errors: [],
  numErrors: 0,
  numProducts: 0,
  numWarnings: 0,
  products: [],
  productsNotFound: [],
  productsNotFoundInSystem: [],
  selectedProducts: {},
  status: 'idle',
  searchedGtins: [],
};

const reducer = (
  state: ProductSearchReducerState,
  action: ProductSearchReducerAction
): ProductSearchReducerState => {
  switch (action.type) {
    case 'IS_SEARCHING': {
      return { ...initialState, status: 'loading' };
    }

    case 'SEARCH_FAILED': {
      return {
        ...state,
        errors: action.payload,
        numErrors: action.payload.length,
        numProducts: 0,
        numWarnings: 0,
        products: [],
        productsNotFound: [],
        productsNotFoundInSystem: [],
        status: 'error',
      };
    }

    case 'SEARCH_SUCCESSFUL': {
      const {
        products,
        productsNotFound,
        productsNotFoundInSystem,
        searchedGtins,
      } = action.payload;

      return {
        ...state,
        errors: [],
        numErrors: 0,
        numProducts: products.length,
        numWarnings: productsNotFound.length + productsNotFoundInSystem.length,
        products,
        productsNotFound,
        productsNotFoundInSystem,
        status: 'success',
        searchedGtins,
      };
    }

    case 'ADD_ERRORS': {
      return {
        ...state,
        errors: [...state.errors, ...action.payload],
        numErrors: state.numErrors + action.payload.length,
      };
    }

    case 'UPDATE_COLUMN_FILTERS': {
      return {
        ...state,
        columnFilters: action.payload,
      };
    }

    case 'UPDATE_SELECTED_PRODUCTS': {
      return {
        ...state,
        selectedProducts: action.payload,
      };
    }

    case 'SET_STATUS': {
      return {
        ...state,
        status: action.payload,
      };
    }
  }
};

const useProductSearchReducer = () => useReducer(reducer, initialState);

export default useProductSearchReducer;
