import axios from 'config/axios';
import { FeedSummary } from '../feeds.types';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

type Response = {
  purposeSummaries: FeedSummary[];
};

async function getFeeds(includeDeleted = false) {
  const response = await axios.get<Response>(
    `GetFeeds?includeDeletedPurposes=${includeDeleted}`
  );

  if (response.status === 204) return [];
  return response.data.purposeSummaries;
}

const useFeeds = (includeDeleted?: boolean) =>
  useQuery<FeedSummary[], AxiosError>(['feeds', includeDeleted], () =>
    getFeeds(includeDeleted)
  );

export default useFeeds;
